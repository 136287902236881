









































import './scss/BaseButton.scss';
import './scss/BaseButtonAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseButton',
    components: {
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    },
})
export default class BaseButton extends Vue {
    @Prop({
        default: 'default',
        validator(value: string) {
            return ['default', 'green', 'blue', 'border-none'].includes(value);
        },
    })
    type!: 'default' | 'green' | 'blue' | 'border-none';
    @Prop( { default: false }) showPreloader!: boolean;
    @Prop( { default: false }) showSignature!: boolean;
    @Prop( { default: false }) showArrow!: boolean;
    @Prop( { default: false }) preloader!: boolean;
    @Prop( { default: false }) disabled!: boolean;
    @Prop( { default: false }) border!: boolean;
}
