var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
        'base-button',
        { 'base-button--green': _vm.type === 'green' },
        { 'base-button--blue': _vm.type === 'blue' },
        { 'base-button--border-none': _vm.type === 'border-none' },
        { 'base-button--disabled': _vm.disabled },
        { 'base-button--border': _vm.border },
        { 'base-button--preloader': _vm.showPreloader } ],attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default"),(_vm.showSignature)?_c('svg',{staticClass:"base-button__signature-icon",attrs:{"width":"18","height":"18","aria-label":"Файл подписан"}},[_c('use',{attrs:{"xlink:href":"#icon-signature"}})]):_vm._e(),(_vm.showArrow)?_c('svg',{staticClass:"base-button__arrow-icon",attrs:{"width":"10","height":"6"}},[_c('use',{attrs:{"xlink:href":"#icon-arrow"}})]):_vm._e(),(_vm.showPreloader)?_c('BasePreloader',{staticClass:"base-button__preloader",attrs:{"type":"button"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }